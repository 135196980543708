<template>
  <div style="width:100%">
    <div class="container">
      <img @click="back" class="back" src="../assets/images/back.png" alt="" />
      <p class="mymsg">邀请人列表</p>
    </div>
    <div class="content">
      <div class="box-list" v-for="item in list" :key="item.id">
        <div class="box-left">
          <img class="img" :src="item.avatar" alt="" />
          <span class="name">{{ item.nickname }}</span>
        </div>
        <strong class="phone">{{ item.mobile | mobileFilter }}</strong>
        <p class="time">{{ item.createtime }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { baseUrl } from '../utils/config'
import { getInviteList } from '../services/home'
import { Toast } from 'vant'
export default {
  filters: {
    mobileFilter(val) {
      const reg = /^(.{3}).*(.{4})$/
      return val.replace(reg, '$1****$2')
    }
  },
  data() {
    return {
      list: []
    }
  },
  async created() {
    const res = await getInviteList()
    console.log(res)
    if (res.data.code === 500) {
      Toast(res.data.msg)
    } else {
      const invite = res.data.data
      invite.forEach(e => {
        e.avatar = baseUrl + e.avatar
        const time = this.$moment(parseInt(e.createtime * 1000)).format(
          'YYYY-MM-DD'
        )
        e.createtime = time
      })
      invite.sort(function(a, b) {
        return b.createtime - a.createtime
      })
      this.list = invite
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 46px;
}

.img {
  display: block;
  height: 24px;
  width: 24px;
}

.box-list {
  display: flex;
  align-items: center;
  padding: 0 5vw;
  margin: 2vw 0px;
}
.box-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 24vw;
}
.name {
  width: 17vw;
  height: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.phone {
  width: 23vw;
  height: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  font-style: normal;
  margin-left: 10vw;
  margin-right: 10vw;
}
.time {
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #5ca5ff;
  line-height: 16px;
  margin: 0;
}
.back{
  z-index: 100;
}
</style>
